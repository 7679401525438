import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Lottie from 'react-lottie';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import animationSend from './animation-success.json';
import animationError from './animation-error.json'

export default function AnimationFinishPost(props) {
  const defaultOptions = (data) => {
    return {
      loop: false,
      autoplay: true,
      animationData: data,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
  }

  return (
    <Dialog
      scroll='body'
      open={props.open}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent>
        <Lottie
          options={defaultOptions(props.success? animationSend : animationError )}
          width={100}
          height={100}
        />
        {props.content && props.content}
        {props.messageText && <Typography align='center' color='textSecondary' style={{ padding: '10px'}}><strong>{props.messageText}</strong></Typography>}
      </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center', height: '50px'}}>
          <Button
            onClick={() => {
              props.closeAnimation(false);
            }}
            size='small'
            variant='contained'
            color="primary"
          >
            {props.textButton}
          </Button>
        </DialogActions>
    </Dialog>
  )
}
